import {
  EventService,
  FirstRankIcon,
  SecondRankIcon,
  TEventRankingItem,
  TGeogroupType,
  ThirdRankIcon,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Search, SentimentVeryDissatisfied, Share } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Select,
  Skeleton,
  SvgIconProps,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../app/context';
import bronze from '../assets/img/leaderboard/bronze.svg';
import first from '../assets/img/leaderboard/first.svg';
import gold from '../assets/img/leaderboard/gold.svg';
import second from '../assets/img/leaderboard/second.svg';
import silver from '../assets/img/leaderboard/silver.svg';
import third from '../assets/img/leaderboard/third.svg';
import Helmet from '../components/helmet';
import LeaderboardShareDialog from '../components/leaderboard-share-dialog';
import { environment } from '../environment';
import useQueryParams from '../hooks/query-params';
import PageLayout from '../layouts/page';
import {
  TEntity,
  areaEntity,
  associationEntity,
  companyEntity,
  educationalEntity,
  entitiesMap,
} from '../models/entities';

const Icons: { [rank: number]: (props: SvgIconProps) => JSX.Element } = {
  1: FirstRankIcon,
  2: SecondRankIcon,
  3: ThirdRankIcon,
};

const groupTypes: TGeogroupType[] = ['city', 'association', 'company', 'school'];

const groupTypeLabelsMap: { [key in TGeogroupType]?: string } = {
  association: 'Association',
  city: 'Territoire',
  company: 'Entreprise',
  other: 'Autre',
  school: 'Établissement scolaire',
};

const podiumSteps = [
  { key: 'second', icon: second, iconHeight: 150, stepHeight: 72 },
  { key: 'first', icon: first, iconHeight: 200, stepHeight: 80 },
  { key: 'third', icon: third, iconHeight: 150, stepHeight: 64 },
];

const medals: { [rank: number]: string } = {
  1: gold,
  2: silver,
  3: bronze,
};

function LeaderboardLayout<T extends string>({
  disableSharing,
  disableFiltering,
  title: pageTitle,
  description,
  entity,
}: {
  description?: string;
  disableFiltering?: boolean;
  disableSharing?: boolean;
  entity: TEntity<T>;
  title?: string;
}): JSX.Element {
  const [theme] = useState(() =>
    createTheme({
      typography: {
        fontFamily: ["'Nunito'", "'Roboto'", 'sans-serif'].join(', '),
      },
      palette: {
        primary: {
          main: entity.color,
        },
        secondary: {
          main: '#e4332a',
        },
      },
    }),
  );
  const { get: getQueryParams, update: updateQueryParams } = useQueryParams<T>();
  const [selectedTypes, selectTypes] = useState<TGeogroupType[]>(
    getQueryParams('types', groupTypes) || [],
  );
  const [selectedSize, selectSize] = useState<T | null>(
    getQueryParams(
      'size',
      entity.sizes.map(({ key }) => key),
    )?.[0] || null,
  );
  const [selectedZone, selectZone] = useState<{ id: number; name: string }>();
  const [search, setSearch] = useState(getQueryParams('search')?.[0] || '');
  const [tmpSearch, setTmpSearch] = useState(search);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>();
  const [items, setItems] = useState<TEventRankingItem[]>();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [shareDialogOpen, openShareDialog] = useState(false);
  const {
    event: { config },
    zones: { map: zonesMap, france, regions, epcisMap, codesMap },
    actions: { getZones },
  } = useContext(AppContext);
  const { zoneCode } = useParams<{ zoneCode?: string }>();
  const navigate = useNavigate();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { toDistance } = useUnits();
  const { pathname } = useLocation();
  const previous = pathname.indexOf('/classements-precedents') === 0;

  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo({ top: 0 });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && !france) getZones();

    setInitialized(true);
  }, [initialized]);

  useEffect(() => {
    if (entity.key === 'area' && zonesMap && codesMap) {
      const id =
        zoneCode &&
        Object.entries(codesMap).find(([, name]) => name.localeCompare(zoneCode) === 0)?.[0];

      if (id) selectZone(zonesMap[parseInt(id)]);
      else
        navigate(
          previous
            ? `/classements-precedents/zones-geographiques/`
            : `/classements/zones-geographiques/`,
        );
    }
  }, [codesMap]);

  useEffect(() => {
    if (!initialized) return;

    if (page > 1) setPage(1);
    else getRanking();

    return () => {
      cancelPromises();
      setItems(undefined);
      setCount(undefined);
    };
  }, [initialized, search, selectedTypes, selectedSize, selectedZone]);

  useEffect(() => {
    if (!initialized) return;

    if (page > 1) getRanking(items);
    else getRanking();

    return () => cancelPromises();
  }, [page]);

  function resetSearch() {
    setTmpSearch('');
    setSearch('');
  }

  async function getRanking(prevPagesItems?: TEventRankingItem[]) {
    const { key: groupType } = entity;

    if (groupType === 'area' && !selectedZone) return;

    setLoading(true);

    try {
      const { count, items } = await cancellablePromise(
        EventService.getRanking(
          config === 'mdv'
            ? environment.mdvEventId
            : previous
              ? environment.prevEventId
              : environment.eventId,
          {
            page,
            pageSize: 50,
            groupTypes: groupType === 'area' ? selectedTypes : [groupType],
            size: entity.sizes.find(({ key }) => key === selectedSize),
            cyclabilityZoneId: selectedZone?.id,
            onlyParentGroups: !selectedZone || (france && selectedZone.id === france.id),
            search,
          },
        ),
      );

      setItems([...(prevPagesItems || []), ...items]);
      setCount(count);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setLoading(false);
      }
    }
  }

  const { key, label: _label, title, sizes } = entity;
  const label = disableFiltering && key === 'area' ? 'Classement général' : _label;

  const _selectedSize = entity.sizes.find(({ key }) => key === selectedSize);
  const hasPodium = !search && !isSmall && items && items.length >= 3;
  const podiumItems = items ? [items[1], items[0], items[2]].filter(Boolean) : undefined;

  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          {pageTitle && <Helmet description={description} title={pageTitle} />}
          <PageLayout fullWidth gap={5}>
            <Box
              alignItems={{ xs: 'stretch', md: 'flex-end' }}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={3}
              justifyContent="space-between"
              maxWidth="calc(100% - 48px)"
              width={1200}
            >
              <Box
                alignItems="flex-start"
                display="flex"
                flexDirection="column"
                flexShrink={0}
                gap={1}
              >
                <Typography
                  alignItems="flex-start"
                  color="primary"
                  columnGap="0.6rem"
                  component="h1"
                  display="flex"
                  flexDirection="column"
                  fontWeight={600}
                  variant="h4"
                  whiteSpace={{ xs: 'initial', md: 'nowrap' }}
                >
                  <Typography component="span" fontWeight={600} variant="h6">
                    {label || 'Classement des'}
                  </Typography>
                  <span>
                    {disableFiltering && entity.key === 'area'
                      ? selectedZone?.name || <Skeleton variant="text" width={200} />
                      : title}
                  </span>
                  {disableFiltering &&
                    entity.key === 'area' &&
                    selectedTypes &&
                    selectedTypes.length > 0 && (
                      <Typography color="primary" fontWeight={600} variant="body1">
                        {selectedTypes.map((key) => entitiesMap[key]?.title).join(', ')}
                      </Typography>
                    )}
                  {disableFiltering && _selectedSize ? (
                    <Typography color="primary" fontWeight={600} variant="body1">
                      {_selectedSize.labelLong}
                    </Typography>
                  ) : (
                    <Typography color="primary" fontWeight={600} variant="body1">
                      &nbsp;
                    </Typography>
                  )}
                </Typography>
                {!disableSharing && (
                  <Button
                    disableElevation
                    color="primary"
                    disabled={!items || Boolean(search)}
                    onClick={() => openShareDialog(true)}
                    size="small"
                    startIcon={<Share />}
                    sx={{
                      borderRadius: 100,
                      textTransform: 'initial',
                    }}
                    variant="contained"
                  >
                    Partager
                  </Button>
                )}
              </Box>
              {!disableFiltering && (
                <Box alignItems="flex-end" display="flex" flexDirection="column" gap={1}>
                  {entity.key === 'area' && (
                    <FormControl margin="none" size="small">
                      <Select
                        native
                        defaultValue=""
                        onChange={({ target: { value } }) => {
                          const zoneId = parseInt(value);
                          if (zonesMap) {
                            selectZone(zonesMap[zoneId]);
                            resetSearch();
                          }
                          if (codesMap)
                            navigate(
                              previous
                                ? `/classements-precedents/zones-geographiques/${codesMap[zoneId]}`
                                : `/classements/zones-geographiques/${codesMap[zoneId]}`,
                            );
                        }}
                        sx={{ borderRadius: 100 }}
                        value={selectedZone?.id.toString()}
                      >
                        {france && <option value={france.id.toString()}>{france.name}</option>}
                        {regions?.map(({ id, name }) => (
                          <optgroup key={id} label={name}>
                            <option value={id.toString()}>{name}</option>
                            {epcisMap?.[id]?.map(({ id: childId, name: childName }) => (
                              <option key={childId} value={childId.toString()}>
                                {childName}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Rechercher">
                            <div>
                              <IconButton
                                disabled={!tmpSearch}
                                onClick={() => {
                                  setSearch(tmpSearch);
                                  updateQueryParams({ search: tmpSearch });
                                }}
                                size="small"
                              >
                                <Search fontSize="small" />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    margin="none"
                    onChange={({ target: { value } }) => setTmpSearch(value)}
                    onKeyDown={({ key }) => {
                      if (key === 'Enter') {
                        setSearch(tmpSearch);
                        updateQueryParams({ search: tmpSearch });
                      }
                    }}
                    placeholder="Rechercher"
                    size="small"
                    sx={{ maxWidth: '100%', width: 300, fieldset: { borderRadius: 100 } }}
                    value={tmpSearch}
                    variant="outlined"
                  />
                  {entity.key === 'area' && (
                    <Box
                      columnGap={1}
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="flex-end"
                      rowGap={0.5}
                    >
                      {[areaEntity, associationEntity, companyEntity, educationalEntity].map(
                        ({ key, title }) => {
                          const active = selectedTypes.includes(key === 'area' ? 'city' : key);

                          return (
                            <Button
                              disableElevation
                              color="secondary"
                              key={key}
                              onClick={() => {
                                const newTypes = active
                                  ? [
                                      ...selectedTypes.filter(
                                        (k) => k !== (key !== 'area' ? key : 'city'),
                                      ),
                                    ]
                                  : [...selectedTypes, key !== 'area' ? key : 'city'];
                                selectTypes(newTypes);
                                resetSearch();
                                updateQueryParams({ types: newTypes, search: '' });
                              }}
                              size="small"
                              sx={{
                                backgroundColor: active ? undefined : '#fff',
                                borderRadius: 100,
                                textTransform: 'initial',
                              }}
                              variant={active ? 'contained' : 'outlined'}
                            >
                              {key === 'area' ? 'Territoires' : title}
                            </Button>
                          );
                        },
                      )}
                    </Box>
                  )}
                  <Box
                    columnGap={1}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    rowGap={0.5}
                  >
                    {sizes.map(({ key, label, labelSmallScreens }) => (
                      <Button
                        disableElevation
                        key={key}
                        onClick={() => {
                          const newSize = selectedSize === key ? null : key;
                          selectSize(newSize);
                          resetSearch();
                          updateQueryParams({ size: newSize, search: '' });
                        }}
                        size="small"
                        sx={{
                          backgroundColor: selectedSize === key ? undefined : '#fff',
                          borderRadius: 100,
                          textTransform: 'initial',
                        }}
                        variant={selectedSize === key ? 'contained' : 'outlined'}
                      >
                        {(isSmall && labelSmallScreens) || label}
                      </Button>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            {items && items.length === 0 ? (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={3}
                paddingX={3}
                paddingY={10}
              >
                <SentimentVeryDissatisfied color="action" sx={{ height: 100, width: 100 }} />
                <Typography align="center" color="textSecondary">
                  Aucune entité ne correspond aux filtres sélectionnés. Essayez de les modifier.
                </Typography>
              </Box>
            ) : (
              <>
                {(!items || hasPodium) && (
                  <Box
                    alignItems={{ xs: 'stretch', md: 'flex-end' }}
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    gap={5}
                    maxWidth="calc(100% - 48px)"
                    width={1200}
                  >
                    {podiumSteps.map(({ key, icon, iconHeight, stepHeight }, index) => {
                      const entity = podiumItems?.[index];
                      const medal = entity ? medals[entity?.rank] : medals[index + 1];

                      return (
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          gap={key === 'first' ? 1 : 2}
                          key={key}
                          width={{ xs: '100%', md: 'calc((100% - 80px) / 3)' }}
                        >
                          <img height={iconHeight} src={icon} />
                          <Box
                            alignSelf="stretch"
                            borderRadius={2}
                            display="flex"
                            gap={2}
                            height={stepHeight}
                            padding={2}
                            sx={{ backgroundColor: '#dce8fa' }}
                          >
                            <Box flexShrink={0}>
                              <img height={key === 'first' ? 40 : 30} src={medal} />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              flexGrow={1}
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              <Typography
                                noWrap
                                title={
                                  entity?.parent_title
                                    ? `${entity.parent_title} - ${entity.title}`
                                    : entity?.title
                                }
                                variant="h6"
                              >
                                {entity ? (
                                  entity.parent_title ? (
                                    `${entity.parent_title} - ${entity.title}`
                                  ) : (
                                    entity.title
                                  )
                                ) : (
                                  <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={300} />
                                )}
                              </Typography>
                              <Typography>
                                {entity ? (
                                  toDistance(entity.progress_value)
                                ) : (
                                  <Skeleton variant="text" width={100} />
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <Box width="100%">
                  {(items || new Array(hasPodium ? 47 : 50).fill(null))
                    .slice(hasPodium ? 3 : 0)
                    .map((result: TEventRankingItem | null, index) => {
                      const Icon =
                        items && result
                          ? (index === 0 || (index > 0 && items[index - 1].rank !== result.rank)) &&
                            Icons[result?.rank]
                          : !hasPodium && Icons[index + 1];

                      return (
                        <Box
                          display="flex"
                          justifyContent="center"
                          key={index}
                          sx={{
                            backgroundColor: index % 2 === 1 ? '#fff' : 'transparent',
                          }}
                        >
                          <Box
                            alignItems="center"
                            display="flex"
                            gap={2}
                            maxWidth="calc(100% - 48px)"
                            minHeight={36}
                            overflow="hidden"
                            width={1200}
                          >
                            <Box display="flex" flexShrink={0} justifyContent="center" width={40}>
                              {Icon ? (
                                <Icon />
                              ) : (
                                <Typography component="span">
                                  {items && result
                                    ? index === 0 || items[index - 1].rank !== result.rank
                                      ? result.rank
                                      : '-'
                                    : index + 1}
                                </Typography>
                              )}
                            </Box>
                            <Box flexGrow={1} overflow="hidden">
                              <Typography>
                                {result ? (
                                  result.parent_title ? (
                                    `${result.parent_title} - ${result.title}`
                                  ) : (
                                    result.title
                                  )
                                ) : (
                                  <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={300} />
                                )}
                              </Typography>
                            </Box>
                            {entity.key === 'area' && (
                              <Box flexShrink={0}>
                                <Typography>
                                  {result ? (
                                    groupTypeLabelsMap[result.type]
                                  ) : (
                                    <Skeleton variant="text" width={100} />
                                  )}
                                </Typography>
                              </Box>
                            )}
                            <Box
                              display="flex"
                              flexShrink={0}
                              justifyContent="flex-end"
                              width={100}
                            >
                              <Typography>
                                {result ? (
                                  toDistance(result.progress_value)
                                ) : (
                                  <Skeleton variant="text" width={100} />
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
                {(count && items && count > items?.length && (
                  <Box alignItems="center" display="flex" gap={2} paddingX={3}>
                    <Typography>
                      Résultats 1 - {items.length} / {count}
                    </Typography>
                    <Button
                      disableElevation
                      disabled={loading}
                      onClick={() => setPage(page + 1)}
                      size="small"
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: 100,
                        textTransform: 'initial',
                      }}
                      variant="outlined"
                    >
                      Voir plus
                    </Button>
                  </Box>
                )) || <></>}
              </>
            )}
          </PageLayout>
        </>
      </ThemeProvider>
      {items && (
        <LeaderboardShareDialog
          entity={entity}
          items={items}
          onClose={() => openShareDialog(false)}
          open={shareDialogOpen}
          selectedSize={selectedSize}
          selectedTypes={selectedTypes}
          selectedZone={selectedZone}
        />
      )}
    </>
  );
}

export default LeaderboardLayout;
